import React from "react";
import { Link } from "gatsby";
import { Button } from "@mui/material";
import { IButtonItem } from "../Interface";

interface Props {
  item: IButtonItem;
}

const LinkButton: React.FC<Props> = ({ item }) => {
  return (
    <div style={{ textDecoration: "none" }}>
      <Button
        variant={item.variant}
        startIcon={item.icon}
        href={item.url || "#"}
        target={item.target}
        size="large"
        onClick={item.onClick}
        sx={{
          borderRadius: 4,
          textTransform: "none",
          justifyContent: "flex-start",
          width: "264px",
          paddingLeft: "37px",
          backgroundColor: "#0c5199",
          fontWeight: "normal",
          ":hover": {
            backgroundColor: "#1a6bc1",
          },
        }}
      >
        {item.label}
      </Button>
    </div>
  );
};

export default LinkButton;
