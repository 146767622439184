import React, { useEffect, useState } from "react";
import { Container } from "@mui/system";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Slide,
} from "@mui/material";
import { Language, Business, Description } from "@mui/icons-material";
import { StaticImage } from "gatsby-plugin-image";
import FooterTree from "../components/FooterTree";
import LinkButton from "../components/LinkButton";
import { IButtonItem } from "../Interface";
import { SEO } from "../components/seo";

const Links: React.FC = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState("");

  const data: IButtonItem[] = [
    {
      label: "+62 811-3320-208",
      variant: "contained",
      icon: (
        <StaticImage
          alt="wa"
          src="../images/links_and_apps/wa.png"
          height={20}
          width={20}
        />
      ),
      url: "https://wa.me/+628113320208",
      target: "_blank",
      func: "url",
    },
    {
      label: "https://garasilabs.com",
      variant: "contained",
      icon: <Language />,
      url: "https://garasilabs.com",
      target: "_blank",
      func: "url",
    },
    {
      label: "garasilabs",
      variant: "contained",
      icon: (
        <StaticImage
          alt="ig garasilabs"
          src="../images/links_and_apps/ig.png"
          height={22}
          width={22}
        />
      ),
      url: "https://instagram.com/garasi.labs",
      target: "_blank",
      func: "url",
    },
    {
      label: "atendi.app",
      variant: "contained",
      icon: (
        <StaticImage
          alt="ig atendi"
          src="../images/links_and_apps/ig.png"
          height={22}
          width={22}
        />
      ),
      url: "https://instagram.com/atendi.app",
      target: "_blank",
      func: "url",
    },
    {
      label: "Atendi attendance app",
      variant: "contained",
      icon: (
        <StaticImage
          alt="atendi"
          src="../images/links_and_apps/atendi.png"
          height={20}
          width={20}
        />
      ),
      url: "https://www.atendi.co/",
      target: "_blank",
      func: "url",
    },
    {
      label: "meet@garasilabs.com",
      variant: "contained",
      icon: (
        <StaticImage
          alt="gmail"
          src="../images/links_and_apps/gmail.png"
          height={20}
          width={20}
        />
      ),
      url: "mailto:meet@garasilabs.com",
      target: "_blank",
      func: "url",
    },
    {
      label: "Get our apps",
      variant: "contained",
      icon: (
        <StaticImage
          alt="apps"
          src="../images/links_and_apps/ps.png"
          height={20}
          width={20}
        />
      ),
      url: "/apps",
      func: "url",
    },
    {
      label: "Address",
      variant: "contained",
      icon: <Business />,
      func: "dialog",
      onClick: () => {
        setDialogOpen((prevState) => !prevState);
        setDialogData(`Ciputra World Office Tower Lt. 30 Unit 3006, Jl. Mayjen Sungkono 89\n Surabaya, Jawa Timur, Indonesia \n
        Phone: +62 31 548-0500`);
      },
    },
    {
      label: "Prospectus",
      variant: "contained",
      icon: <Description />,
      url: "/Prospectus.pdf",
      target: "_blank ",
      func: "url",
    },
  ];

  return (
    <Container disableGutters maxWidth="xs">
      <div
        style={{
          backgroundColor: "#FFFFFF",
          height: "100vh",
          overflow: "auto",
        }}
      >
        <StaticImage alt="cover" src="../images/links_and_apps/cover.jpg" />
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <Grid item xs={12} marginBottom="10px">
            <Grid container justifyContent="center" spacing={0.5}>
              <Grid item xs={5}>
                <div style={{ textAlign: "right", color: "#0c5199" }}>
                  Building
                </div>
              </Grid>
              <Grid item xs={7}>
                <div
                  style={{
                    textAlign: "left",
                    color: "#0c5199",
                    fontWeight: "bold",
                  }}
                >
                  better software
                </div>
              </Grid>
              <Grid item xs={5}>
                <div style={{ textAlign: "right", color: "#0c5199" }}>for</div>
              </Grid>
              <Grid item xs={7}>
                <div
                  style={{
                    textAlign: "left",
                    color: "#0c5199",
                    fontWeight: "bold",
                  }}
                >
                  better business
                </div>
              </Grid>
              <Grid item xs={5} />
              <Grid item xs={7}>
                <div
                  style={{
                    textAlign: "left",
                    color: "#0c5199",
                    fontWeight: "bold",
                  }}
                >
                  with us!
                </div>
              </Grid>
            </Grid>
          </Grid>
          {data.map((item: IButtonItem, i: number) => (
            <Grid item xs={12} key={i}>
              <LinkButton item={item} />
            </Grid>
          ))}
          <Grid item xs={12} marginTop="50px" marginBottom="20px">
            <FooterTree />
          </Grid>
        </Grid>
      </div>
      <Dialog onClose={() => setDialogOpen(false)} open={dialogOpen}>
        <DialogTitle>Address</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ whiteSpace: "pre-line" }}
          >
            {dialogData}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default Links;

export const Head = () => <SEO title="Links | Garasilabs" />;
