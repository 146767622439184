import React, { useEffect, useState } from "react";

const FooterTree: React.FC = () => {
  const [year, setYear] = useState<number>(0);

  useEffect(() => {
    const thisYear: Date = new Date();
    setYear(thisYear.getFullYear());
  }, []);

  return (
    <div style={{ textAlign: "center", color: "gray", fontSize: "12px" }}>
      Copyright &copy; {year} by Garasilabs
    </div>
  );
};

export default FooterTree;
